function Calendar(element, instanceOptions) {
	var t = this;

	// Build options object
	// -----------------------------------------------------------------------------------
	// Precedence (lowest to highest): defaults, rtlDefaults, langOptions, instanceOptions

	instanceOptions = instanceOptions || {};

	var options = mergeOptions({}, defaults, instanceOptions);
	var langOptions;

	// determine language options
	if (options.lang in langOptionHash) {
		langOptions = langOptionHash[options.lang];
	} else {
		langOptions = langOptionHash[defaults.lang];
	}

	if (langOptions) {
		// if language options exist, rebuild...
		options = mergeOptions({}, defaults, langOptions, instanceOptions);
	}

	if (options.isRTL) {
		// is isRTL, rebuild...
		options = mergeOptions(
			{},
			defaults,
			rtlDefaults,
			langOptions || {},
			instanceOptions
		);
	}

	// Exports
	// -----------------------------------------------------------------------------------

	t.options = options;
	t.updateConfig = updateConfig;
	t.render = render;
	t.destroy = destroy;
	t.refetchEvents = refetchEvents;
	t.getEvents = getEvents;
	t.getUnscheduledEvents = getUnscheduledEvents;
	t.reportEvents = reportEvents;
	t.reportMapEvents = reportMapEvents;
	t.reportUnscheduledEvents = reportUnscheduledEvents;
	t.reportAltViewDragStart = reportAltViewDragStart;
	t.reportAltViewDrag = reportAltViewDrag;
	t.reportAltViewDragStop = reportAltViewDragStop;
	t.reportEventChange = reportEventChange;
	t.rerenderUnscheduledEvents = rerenderUnscheduledEvents;
	t.rerenderEvents = rerenderEvents;
	t.changeView = changeView;
	t.select = select;
	t.unselect = unselect;
	t.prev = prev;
	t.next = next;
	t.prevYear = prevYear;
	t.nextYear = nextYear;
	t.today = today;
	t.gotoDate = gotoDate;
	t.incrementDate = incrementDate;
	t.getDate = getDate;
	t.getCalendar = getCalendar;
	t.getView = getView;
	t.option = option;
	t.trigger = trigger;
	t.redraw = redraw;

	t.formatLocalizedDate = formatLocalizedDate;
	t.localizeDateFormat = localizeDateFormat;

	t.initialized = function () {
		return initialized;
	};

	//Customized for SeedCode
	var customRepeats;
	var forceFetch;
	var forceNoFetch;
	var isRefresh;
	var scrollPosition;
	var initialized;

	t.isCustomRepeats = isCustomRepeats;
	t.isForceFetch = isForceFetch;
	t.setForceFetch = setForceFetch;
	t.isForceNoFetch = isForceNoFetch;
	t.setForceNoFetch = setForceNoFetch;
	t.getContentWidth = getContentWidth;

	t.setScrollPosition = setScrollPosition;
	t.getScrollPosition = getScrollPosition;

	t.timelineTimeoutID = null;

	t.updateOptions = updateOptions;

	//Can be used to update a config option and re-render the calendar to reflect that - For example scrolling the grid times
	function updateOptions(option, value, render) {
		options[option] = value;
		if (render) {
			t.render();
		}
	}

	function getContentWidth() {
		return content.width();
	}

	function isCustomRepeats() {
		var view = getView();
		var customRepetitions =
			(options.isSalesforce && options.hideSalesforceRepetitions) ||
			(options.hasGoogleCalendar &&
				(options.automaticRepetitions ||
					options.hideDailyRepetitions ||
					options.hideWeeklyRepetitions ||
					options.hideMonthlyRepetitions ||
					options.hideYearlyRepetitions));

		if (
			customRepetitions &&
			(previousViewName === 'basicHorizon' ||
				view.name === 'basicHorizon') &&
			previousViewName !== view.name
		) {
			return true;
		} else {
			return false;
		}
	}

	function isForceNoFetch() {
		return forceNoFetch;
	}

	function setForceNoFetch(isForceNoFetch) {
		forceNoFetch = isForceNoFetch;
		return isForceNoFetch;
	}

	function isForceFetch() {
		return forceFetch;
	}

	function setForceFetch(isForceFetch) {
		forceFetch = isForceFetch;
		return forceFetch;
	}

	function getScrollElement() {
		return $('.calendar-scroll');
	}
	function getScrollPosition() {
		var scrollElement = getScrollElement();
		return scrollElement.scrollTop();
	}
	function setScrollPosition(top) {
		scrollPosition = top;
	}
	function applyScrollPosition(top) {
		var scrollElement = getScrollElement();
		var scrollTo = top === undefined ? scrollPosition : top;
		if (scrollTo !== undefined) {
			//Scroll to position
			scrollElement.scrollTop(scrollTo);
			//Clear saved scroll position
			scrollPosition = undefined;
		}
	}

	// Language-data Internals
	// -----------------------------------------------------------------------------------
	// Apply overrides to the current language's data

	// Returns moment's internal locale data. If doesn't exist, returns English.
	// Works with moment-pre-2.8
	function getLocaleData(langCode) {
		var f = moment.localeData || moment.langData;
		return f.call(moment, langCode) || f.call(moment, 'en'); // the newer localData could return null, so fall back to en
	}

	var localeData = createObject(getLocaleData(options.lang)); // make a cheap copy

	if (options.monthNames) {
		localeData._months = options.monthNames;
	}
	if (options.monthNamesShort) {
		localeData._monthsShort = options.monthNamesShort;
	}
	if (options.dayNames) {
		localeData._weekdays = options.dayNames;
	}
	if (options.dayNamesShort) {
		localeData._weekdaysShort = options.dayNamesShort;
	}
	if (options.firstDay != null) {
		var _week = createObject(localeData._week); // _week: { dow: # }
		_week.dow = options.firstDay;
		localeData._week = _week;
	}

	// Calendar-specific Date Utilities
	// -----------------------------------------------------------------------------------
	//

	/** @type{(viewNameOverride?: string) => Object} */
	function getClusterDefinitions(viewNameOverride) {
		const view = viewNameOverride || getView();
		const config = options;

		if (view?.name === 'basicHorizon') {
			return fc.horizonSliderDefinitions(config.horizonSlider);
		} else {
			return fc.resourceDaysClusterDefinitions(config.resourceDays);
		}
	}

	fc.getClusterDefinitions = getClusterDefinitions; // expose

	function createTimezoneTime(date, isAllDay, isUTC) {
		// date could be a moment object, jsDate, or string (anything moment can parse)
		var config = options;

		if (config.clientTimezone && date && !isAllDay) {
			return moment(moment(date).tz(config.clientTimezone).toArray());
		} else {
			// if (date, isAllDay, isUTC) {
			// 	return moment(date.toDate());
			// }
			// else {
			return date;
			// }
		}
	}
	fc.createTimezoneTime = createTimezoneTime; // expose

	function timezoneTimeToLocalTime(date, isAllDay) {
		// date should be a moment object
		var config = options;

		if (config.clientTimezone && date && !isAllDay) {
			if (!config.localTimezone) {
				config.localTimezone = moment.tz.guess();
			}
			return moment(
				date
					.tz(config.clientTimezone, true)
					.tz(config.localTimezone)
					.toArray()
			);
		}
		return date ? moment(date) : date;
	}
	fc.timezoneTimeToLocalTime = timezoneTimeToLocalTime; // expose

	t.defaultAllDayEventDuration = moment.duration(
		options.defaultAllDayEventDuration
	);
	t.defaultTimedEventDuration = moment.duration(
		options.defaultTimedEventDuration
	);

	// Builds a moment using the settings of the current calendar: timezone and language.
	// Accepts anything the vanilla moment() constructor accepts.
	t.moment = function () {
		var mom;

		if (options.timezone === 'local') {
			mom = fc.moment.apply(null, arguments);

			// Force the moment to be local, because fc.moment doesn't guarantee it.
			if (mom.hasTime()) {
				// don't give ambiguously-timed moments a local zone
				mom.local();
			}
		} else if (options.timezone === 'UTC') {
			mom = fc.moment.utc.apply(null, arguments); // process as UTC
		} else {
			mom = fc.moment.parseZone.apply(null, arguments); // let the input decide the zone
		}

		if ('_locale' in mom) {
			// moment 2.8 and above
			mom._locale = localeData;
		} else {
			// pre-moment-2.8
			mom._lang = localeData;
		}

		return mom;
	};

	// Returns a boolean about whether or not the calendar knows how to calculate
	// the timezone offset of arbitrary dates in the current timezone.
	t.getIsAmbigTimezone = function () {
		return options.timezone !== 'local' && options.timezone !== 'UTC';
	};

	// Returns a copy of the given date in the current timezone of it is ambiguously zoned.
	// This will also give the date an unambiguous time.
	t.rezoneDate = function (date) {
		return t.moment(date.toArray());
	};

	// Returns a copy of the given date without a timezone and set to UTC.
	// This will also give the date an ambiguous time.
	t.unzoneDate = function (date) {
		return fc.moment.utc(date.toArray());
	};

	// Returns a moment for the current date, as defined by the client's computer,
	// or overridden by the `now` option.
	t.getNow = function () {
		var now = options.now;
		if (typeof now === 'function') {
			now = now();
		} else {
			now = moment();
		}
		return t.moment(createTimezoneTime(now));
	};

	// Calculates the week number for a moment according to the calendar's
	// `weekNumberCalculation` setting.
	t.calculateWeekNumber = function (mom, dayCnt) {
		var calc = options.weekNumberCalculation;
		return calculateWeekNumber(calc, mom, dayCnt);
	};

	// Get an event's normalized end date. If not present, calculate it from the defaults.
	t.getEventEnd = function (event) {
		if (event.end) {
			return event.end.clone();
		} else {
			return t.getDefaultEventEnd(event.allDay, event.start);
		}
	};

	// Given an event's allDay status and start date, return swhat its fallback end date should be.
	t.getDefaultEventEnd = function (allDay, start) {
		// TODO: rename to computeDefaultEventEnd
		var end = start.clone();

		if (allDay) {
			end.stripTime().add(t.defaultAllDayEventDuration);
		} else {
			end.add(t.defaultTimedEventDuration);
		}

		if (t.getIsAmbigTimezone()) {
			end.stripZone(); // we don't know what the tzo should be
		}

		return end;
	};

	// Date-formatting Utilities
	// -----------------------------------------------------------------------------------

	// Like the vanilla formatRange, but with calendar-specific settings applied.
	t.formatRange = function (m1, m2, formatStr) {
		// a function that returns a formatStr // TODO: in future, precompute this
		if (typeof formatStr === 'function') {
			formatStr = formatStr.call(t, options, localeData);
		}

		return formatRange(m1, m2, formatStr, null, options.isRTL);
	};

	// Like the vanilla formatDate, but with calendar-specific settings applied.
	t.formatDate = function (mom, formatStr) {
		// a function that returns a formatStr // TODO: in future, precompute this
		if (typeof formatStr === 'function') {
			formatStr = formatStr.call(t, options, localeData);
		}

		return formatDate(mom, formatStr);
	};

	// Imports
	// -----------------------------------------------------------------------------------

	EventManager.call(t, options);
	var isFetchNeeded = t.isFetchNeeded;
	var fetchEvents = t.fetchEvents;
	var fetchUnscheduledEvents = t.fetchUnscheduledEvents;
	var getPendingSourceCount = t.getPendingSourceCount;
	var getPendingUnscheduledSourceCount = t.getPendingUnscheduledSourceCount;

	// fetch resources
	ResourceManager.call(t, options);
	var fetchResources = t.fetchResources;

	// Locals
	// -----------------------------------------------------------------------------------

	var _element = element[0];
	var header;
	var headerElement;
	var content;
	var tm; // for making theme classes
	var currentView;
	var previousViewName;
	var elementOuterWidth;
	var suggestedViewHeight;
	var resizeUID = 0;
	var ignoreWindowResize = 0;
	var date;
	var today;
	var events = [];
	var unscheduledEvents = [];
	var _dragElement;
	var waitingToRerender;
	var lastAltViewDragState;

	function updateConfig(configItem, value) {
		instanceOptions[configItem] = value;
		options = mergeOptions({}, defaults, instanceOptions);
	}

	// Main Rendering
	// -----------------------------------------------------------------------------------

	if (options.defaultDate != null) {
		date = t.moment(options.defaultDate);
	} else {
		date = t.getNow();
	}

	function render(inc, resetScroll) {
		if (!content) {
			initialRender();
		} else {
			// mainly for the public API
			calcSize();
			_renderView(inc, null, null, resetScroll);
		}

		// If the element isn't visible then watch for visibility and re-render once it's visible
		if (!elementVisible()) {
			content.css('opacity', 0.1); // Set opacity to a low number so the content is visible but light enough so we don't see squished tables
			content.css('transition', 'opacity 1s');
			updateSizeAfterVisible(function () {
				content.css('opacity', ''); // Reset the opacity so we can see the content after the element is visible;
				content.css('transition', '');
			});
		}
	}

	function initialRender() {
		initialized = true;
		tm = options.theme ? 'ui' : 'fc';
		element.addClass('fc');
		if (options.isRTL) {
			element.addClass('fc-rtl');
		} else {
			element.addClass('fc-ltr');
		}
		if (options.theme) {
			element.addClass('ui-widget');
		}

		content = $("<div class='fc-content' />").prependTo(element);

		header = new Header(t, options);
		headerElement = header.render();
		if (headerElement) {
			element.prepend(headerElement);
		}

		changeView(options.defaultView);

		if (options.handleWindowResize) {
			$(window).resize(windowResize);
		}
	}

	function destroy() {
		// If timeline timeOut is set then clear before instantiating new view
		if (t.timelineTimeoutID) {
			window.clearTimeout(t.timelineTimeoutID);
		}

		if (currentView) {
			trigger(
				'viewDestroy',
				currentView,
				currentView,
				currentView.element
			);
			currentView.triggerEventDestroy();
		}

		$(window).unbind('resize', windowResize);

		header.destroy();
		content.remove();
		element.removeClass('fc fc-rtl ui-widget');
		initialized = false;
	}

	function elementVisible() {
		// This function isn't necessary as it was used for edge cases with old versions of IE.
		// Leaving here and rerturning true in case it is found to need to be re-enabled. This we the references can stay.
		return element.is(':visible');
	}

	function bodyVisible() {
		// This function isn't necessary as it was used for edge cases with old versions of IE.
		// Leaving here and rerturning true in case it is found to need to be re-enabled. This we the references can stay.
		return $('body').is(':visible');
	}

	// View Rendering
	// -----------------------------------------------------------------------------------

	function changeView(newViewName, forceRedraw) {
		if (!currentView || newViewName != currentView.name || forceRedraw) {
			_changeView(newViewName);
		}
	}

	function _changeView(newViewName) {
		ignoreWindowResize++;

		if (currentView) {
			// Record the last view name
			previousViewName = currentView.name;
			trigger(
				'viewDestroy',
				currentView,
				currentView,
				currentView.element
			);
			unselect();
			currentView.triggerEventDestroy(); // trigger 'eventDestroy' for each event
			freezeContentHeight();
			currentView.element.remove();
			header.deactivateButton(currentView.name);
			//Customized for SeedCode - Removes our scroll class because we apply this class when the view renders only for specific views
			content.removeClass('calendar-scroll touch-scroll');
		}
		//Customized for SeedCode - We are clearing the day names header if it has been broken out previously.
		var breakoutHeader = t.options.breakoutHeader;
		var breakoutHeaderElement = $('.fc-breakout-header');
		var timelineTimeElement;
		if (breakoutHeader && breakoutHeaderElement.length) {
			breakoutHeaderElement.remove();
			timelineTimeElement = $('#timelineTimeVertContainer');
			if (timelineTimeElement && timelineTimeElement.length) {
				timelineTimeElement.remove();
			}
			content.css('top', ''); //Reset our container's top position
		}
		//
		header.activateButton(newViewName);

		currentView = new fcViews[newViewName](
			$("<div class='fc-view fc-view-" + newViewName + "' />").appendTo(
				content
			),
			t // the calendar object
		);

		renderView(null, null, true);
		unfreezeContentHeight();

		ignoreWindowResize--;
	}

	function renderView(inc, alt, fromViewChange) {
		if (
			!currentView.start || // never rendered before
			inc ||
			inc === 0 || // explicit date window change
			!date.isWithin(
				currentView.intervalStart,
				currentView.intervalEnd
			) || // implicit date window change
			(currentView.renderOnIntervalStart &&
				!date.isSame(
					currentView.startOverride || currentView.intervalStart
				))
		) {
			_renderView(inc, alt, fromViewChange, false);
		}
	}

	function _renderView(inc, alt, fromViewChange, resetScroll) {
		// assumes elementVisible

		// If timeline timeOut is set then clear before instantiating new view
		if (t.timelineTimeoutID) {
			window.clearTimeout(t.timelineTimeoutID);
		}

		ignoreWindowResize++;

		today = t.getNow();

		if (currentView.start) {
			// already been rendered?
			trigger(
				'viewDestroy',
				currentView,
				currentView,
				currentView.element
			);
			unselect();
			clearEvents();
		}

		freezeContentHeight();
		if (inc) {
			date = currentView.incrementDate(date, inc, alt);
		}

		if (options.minDate) {
			if (date.isBefore(moment(options.minDate))) {
				date = t.moment(options.minDate);
			}
		}

		currentView.render(date.clone()); // the view's render method ONLY renders the skeleton, nothing else

		//
		setSize();

		unfreezeContentHeight();
		(currentView.afterRender || noop)();

		updateTitle();
		updateTodayButton();

		trigger('viewRender', currentView, currentView, currentView.element);

		ignoreWindowResize--;

		getAndRenderEvents(fromViewChange, resetScroll);
	}

	// Resizing
	// -----------------------------------------------------------------------------------

	function updateSize() {
		if (elementVisible()) {
			// If we are waiting to re-render then don't bother rerendering the events because it will happen shortly
			if (!waitingToRerender) {
				unselect();
				clearEvents();
			}
			calcSize();
			setSize();
			if (!waitingToRerender) {
				renderEvents(null, null, true);
			}
		}
	}

	function updateSizeAfterVisible(callback) {
		if (elementVisible()) {
			handleVisibilityChange();

			if (callback) {
				callback();
			}
		} else {
			waitingToRerender = window.setTimeout(function () {
				updateSizeAfterVisible(callback);
			}, 20);
		}
	}

	function handleVisibilityChange() {
		window.clearTimeout(waitingToRerender);
		changeView(currentView.name, true);
	}
	function calcSize() {
		// assumes elementVisible
		if (options.contentHeight) {
			suggestedViewHeight = options.contentHeight;
		} else if (options.height) {
			suggestedViewHeight =
				options.height -
				(headerElement ? headerElement.height() : 0) -
				vsides(content);
		} else {
			suggestedViewHeight = Math.round(
				content.width() / Math.max(options.aspectRatio, 0.5)
			);
		}
	}

	function setSize() {
		// assumes elementVisible

		if (suggestedViewHeight === undefined) {
			calcSize(); // for first time
			// NOTE: we don't want to recalculate on every renderView because
			// it could result in oscillating heights due to scrollbars.
		}
		ignoreWindowResize++;
		currentView.setHeight(suggestedViewHeight);
		currentView.setWidth(content.width());
		ignoreWindowResize--;

		elementOuterWidth = element.outerWidth();
	}

	function windowResize() {
		if (!ignoreWindowResize) {
			// view has already been rendered
			var uid = ++resizeUID;
			setTimeout(function () {
				// add a delay
				if (
					uid == resizeUID &&
					!ignoreWindowResize &&
					elementVisible() &&
					!waitingToRerender
				) {
					if (
						elementOuterWidth !=
						(elementOuterWidth = element.outerWidth())
					) {
						ignoreWindowResize++; // in case the windowResize callback changes the height
						updateSize();
						currentView.trigger('windowResize', _element);
						ignoreWindowResize--;
					}
				}
			}, 200);
		}
	}

	/* Event Fetching/Rendering
	-----------------------------------------------------------------------------*/
	// TODO: going forward, most of this stuff should be directly handled by the view

	function refetchEvents(refresh) {
		// can be called as an API method
		isRefresh = refresh;
		var now = t.getNow();
		if (
			now.isSame(today, 'day') ||
			(now.isBefore(currentView.startOverride || currentView.start) &&
				today.isBefore(
					currentView.startOverride || currentView.start
				)) ||
			(now.isAfter(currentView.end) &&
				today.isAfter(currentView.startOverride || currentView.start))
		) {
			//Refresh events only if today is the same or if today is out of view
			clearEvents();
			fetchAndRenderEvents();
		} else {
			//We want to re-render our view because today has changed so we want to reflect that
			setScrollPosition(getScrollPosition());
			t.setForceFetch(true);
			_renderView();
		}

		// Reload unscheduled events
		fetchUnscheduledEvents(false, null);
	}

	function rerenderUnscheduledEvents() {
		// can be called as an API method
		renderUnscheduledEvents();
	}

	function renderUnscheduledEvents(modifiedEventID) {
		var afterRenderData = {};
		// Rerender
		t.renderUnscheduledEvents(modifiedEventID);

		afterRenderData.refresh = isRefresh;
		//Reset isRefresh
		if (isRefresh) {
			isRefresh = false;
		}
		currentView.trigger(
			'eventAfterAllRender',
			afterRenderData,
			afterRenderData
		);
	}

	function clearUnscheduledEvents() {
		t.clearUnscheduledEvents();
	}

	function rerenderEvents(modifiedEventID) {
		// can be called as an API method
		clearEvents();
		renderEvents(modifiedEventID);
	}

	function renderEvents(modifiedEventID, resetScroll, fromResize) {
		// TODO: remove modifiedEventID hack
		var afterRenderData = {};
		if (elementVisible()) {
			// Check if the timezone has changed and if it has re-render the view
			var dateOffsetCompare = moment(
				options.timezoneDateCompare
			).utcOffset();
			if (dateOffsetCompare !== options.timezoneOffsetCompare) {
				window.location.reload();
				return;
			}

			currentView.renderEvents(events, modifiedEventID); // actually render the DOM elements
			if (!resetScroll) {
				applyScrollPosition(); //Apply any defined scroll position. This needs to happen after events render so we have somewhere to scroll
			}
			afterRenderData.refresh = isRefresh;
			afterRenderData.resize = fromResize;
			//Reset isRefresh
			if (isRefresh) {
				isRefresh = false;
			}
			currentView.trigger(
				'eventAfterAllRender',
				afterRenderData,
				afterRenderData
			);
		}
	}

	function clearEvents() {
		currentView.triggerEventDestroy(); // trigger 'eventDestroy' for each event
		currentView.clearEvents(); // actually remove the DOM elements
		currentView.clearEventData(); // for View.js, TODO: unify with clearEvents
	}

	function getAndRenderEvents(fromViewChange, resetScroll) {
		//Check if we want to force an event fetch
		var isCustomRepeats;
		var isForceFetch = t.isForceFetch();
		var isForceNoFetch = t.isForceNoFetch();

		if (fromViewChange) {
			isCustomRepeats = t.isCustomRepeats();
		}

		currentView.trigger('eventBeforeAllRender');

		if (
			(!isForceNoFetch &&
				(isCustomRepeats || isForceFetch || !options.lazyFetching)) ||
			isFetchNeeded(
				currentView.startOverride || currentView.start,
				currentView.end
			)
		) {
			fetchAndRenderEvents();
		} else {
			renderEvents(null, resetScroll);
		}
		//If we forced an event check reset that so we don't continue to do so unwantingly
		if (isForceFetch) {
			t.setForceFetch(null);
		}
		if (isForceNoFetch) {
			t.setForceNoFetch(null);
		}
	}

	function fetchAndRenderEvents() {
		fetchEvents(
			currentView.startOverride || currentView.start,
			currentView.end
		);
		// ... will call reportEvents
		// ... which will call renderEvents
	}

	function getEvents(start, end, callback) {
		fetchEvents(start, end, true, callback);
		//Only fetch events
	}

	function getUnscheduledEvents(callback) {
		fetchUnscheduledEvents(true, callback);
		//Only fetch events
	}

	// called when event data arrives
	function reportEvents(_events) {
		events = _events;
		renderEvents();
	}

	// called when map event data arrives
	function reportMapEvents(_events) {
		mapEvents = _events;
		var callback = t.getReportingCallback('mapEvents');
		// Run callbacks
		if (callback) {
			callback(mapEvents, true);
		}
	}

	// called when unscheduled event data arrives
	function reportUnscheduledEvents(_events) {
		unscheduledEvents = _events;
		var callback = t.getReportingCallback('unscheduled');
		// Run callbacks
		if (callback) {
			callback(unscheduledEvents);
		}
	}

	// called when all schedules have loaded
	// Expose to call globally
	fc.reportSchedulesLoaded = reportSchedulesLoaded;
	function reportSchedulesLoaded() {
		var callback = t.getReportingCallback('reportSchedulesLoaded');
		// Run callbacks
		if (callback) {
			callback();
		}
	}

	// Expose to call globally
	fc.reportAltViewDragStart = reportAltViewDragStart;
	function reportAltViewDragStart(jsEvent, ui) {
		// Reset last alt view drag state
		lastAltViewDragState = false;
		return false;
	}

	// Exposeto call globally
	fc.reportAltViewDrag = reportAltViewDrag;
	function reportAltViewDrag(jsEvent, ui, container, event, isClone) {
		var callback = t.getReportingCallback('unscheduledDrag');
		var isHovering = callback
			? callback(jsEvent.originalEvent, isClone, event)
			: false;
		var appendContainer =
			isHovering && container ? document.body : container;
		var changed = lastAltViewDragState !== isHovering;
		if (changed && appendContainer) {
			appendContainer.appendChild(ui.helper[0]);
			if (isHovering) {
				ui.helper[0].style.zIndex = '999999';
			} else {
				ui.helper[0].style.Index = '0';
			}
		}
		lastAltViewDragState = isHovering;
		return isHovering;
	}

	// Exposeto call globally
	fc.reportAltViewDragStop = reportAltViewDragStop;
	function reportAltViewDragStop(jsEvent, ui) {
		var callback = t.getReportingCallback('unscheduledDrop');
		var isHovering = callback ? callback(jsEvent.originalEvent, ui) : false;
		return isHovering;
	}

	// called when a single event's data has been changed
	function reportEventChange(eventID) {
		rerenderEvents(eventID);
	}

	/* Header Updating
	-----------------------------------------------------------------------------*/

	function updateTitle() {
		header.updateTitle(currentView.title);
	}

	function updateTodayButton() {
		var now = t.getNow();
		if (now.isWithin(currentView.intervalStart, currentView.intervalEnd)) {
			header.disableButton('today');
		} else {
			header.enableButton('today');
		}
	}

	/* Selection
	-----------------------------------------------------------------------------*/

	function select(start, end) {
		currentView.select(start, end);
	}

	function unselect() {
		// safe to be called before renderView
		if (currentView) {
			currentView.unselect();
		}
	}

	/* Date
	-----------------------------------------------------------------------------*/

	function prev(alt) {
		renderView(-1, alt);
	}

	function next(alt) {
		renderView(1, alt);
	}

	function prevYear() {
		date.add(-1, 'years');
		renderView();
	}

	function nextYear() {
		date.add(1, 'years');
		renderView();
	}

	function today(alt) {
		date = t.getNow();
		renderView(0, alt);
	}

	function gotoDate(dateInput) {
		let inc;
		date = t.moment(dateInput);
		// Test for multi-week views so we allow navigating dates in different weeks
		// but not in the same week
		if (
			(currentView.name === 'basicWeek' ||
				currentView.name === 'agendaWeek') &&
			date.week() !== currentView.intervalStart.week()
		) {
			inc = 0;
		}
		renderView(inc);
	}

	function incrementDate(delta) {
		date.add(moment.duration(delta));
		renderView();
	}

	function getDate() {
		return date.clone();
	}

	/* Height "Freezing"
	-----------------------------------------------------------------------------*/

	function freezeContentHeight() {
		content.css({
			width: '100%',
			height: content.height(),
			overflow: 'hidden',
		});
	}

	function unfreezeContentHeight() {
		content.css({
			width: '',
			height: '',
			overflow: '',
		});
	}

	/* Misc
	-----------------------------------------------------------------------------*/

	function redraw(alt) {
		// date = t.getNow();
		setScrollPosition(getScrollPosition());
		renderView(0);
	}

	function getCalendar() {
		return t;
	}

	function getView() {
		return currentView;
	}

	function option(name, value) {
		if (value === undefined) {
			return options[name];
		}
		if (
			name == 'height' ||
			name == 'contentHeight' ||
			name == 'aspectRatio'
		) {
			options[name] = value;

			updateSize();
		}
	}

	function trigger(name, thisObj) {
		if (options[name]) {
			return options[name].apply(
				thisObj || _element,
				Array.prototype.slice.call(arguments, 2)
			);
		}
	}

	/* External Dragging
	------------------------------------------------------------------------*/

	if (options.droppable) {
		// TODO: unbind on destroy
		$(document)
			.bind('dragstart', function (ev, ui) {
				var _e = ev.target;
				var e = $(_e);
				if (!e.parents('.fc').length) {
					// not already inside a calendar
					var accept = options.dropAccept;
					if (
						$.isFunction(accept) ? accept.call(_e, e) : e.is(accept)
					) {
						_dragElement = _e;
						currentView.dragStart(_dragElement, ev, ui);
					}
				}
			})
			.bind('dragstop', function (ev, ui) {
				if (_dragElement) {
					currentView.dragStop(_dragElement, ev, ui);
					_dragElement = null;
				}
			});
	}
}
